import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'
import { ShareSelectInput } from '@components'
import {
  EHabitGoalStatus,
  PATH_SUB_HABIT_QUESTION_DETAIL,
  enumTableTitleHabitQuestion,
  thunkActionLoading,
} from '@configs'
import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { Card, Skeleton, Tooltip, message } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Input, Loading, SharedTable } from 'src/common'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { useDebounce } from 'src/hooks/useDebounce'
import {
  IGetGoalParams,
  IGetQuestionParams,
  IQuestionItem,
} from 'src/interfaces/habit-management'
import {
  deleteQuestionAction,
  getListQuestionAction,
} from 'src/redux/actions/habit-management'

type Props = {}

const QuestionHabitPage = (props: Props) => {
  const { state } = useLocation()

  const [isModalLoading, setIsModalLoading] = useState(false)

  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)

  const { t } = useTranslation(['error', 'common'])

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [deletedQuestion, setDeletedQuestion] = useState<IQuestionItem>()

  const [selectedQuestion, setSelectedQuestion] = useState<IQuestionItem>()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [status, setStatus] = useState<boolean | undefined>(undefined)

  const debouncedValue = useDebounce<string | null>(searchText, 500)
  const [isSearching, setIsSearching] = useState(false)

  const { question } = useAppSelector((state: RootState) => state.habit)

  const questionLoading = useAppSelector(
    (state: RootState) =>
      state.habit.loadings[thunkActionLoading.GET_LIST_QUESTIONS_LOADING]
  )

  const getQuestions = async (params?: IGetQuestionParams) => {
    dispatch(
      getListQuestionAction({
        page: page,
        limit: 10,
        goalId: state?.goalId,
        search:
          debouncedValue !== null && debouncedValue.trim()
            ? debouncedValue.trim()
            : undefined,
        status: status,
        ...(params || {}),
      })
    )
  }

  const getQuestionsBySearchValue = async () => {
    try {
      setIsSearching(true)
      await dispatch(
        getListQuestionAction({
          page: 1,
          goalId: state?.goalId,
          search:
            debouncedValue !== null && debouncedValue.trim()
              ? debouncedValue.trim()
              : undefined,
        })
      ).unwrap()
    } catch (error: any) {
      if (error.message) {
        message.error(error.message)
      }
    } finally {
      setIsSearching(false)
    }
  }

  const onDeleteQuestion = async () => {
    if (!deletedQuestion || !deletedQuestion?.id) return
    setIsModalLoading(true)
    try {
      const response = await dispatch(
        deleteQuestionAction(deletedQuestion?.id as number)
      ).unwrap()
      if (response?.success) message.success(response.message)
      getQuestions()
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setDeletedQuestion(undefined)
      setOpenConfirmDeleteModal(false)
      setIsModalLoading(false)
    }
  }

  const onCloseDeleteModal = () => {
    setOpenConfirmDeleteModal(false)
    setDeletedQuestion(undefined)
  }

  const columns = [
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleHabitQuestion.NO}</p>
          </div>
        )
      },
      dataIndex: 'no',
      align: 'center',
      key: 'no',
      width: '5%',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: false,
    },
    {
      title: enumTableTitleHabitQuestion.Q_EN,
      dataIndex: 'question',
      key: 'question',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '35%',
    },
    {
      title: enumTableTitleHabitQuestion.Q_LN,
      dataIndex: 'questionInDutch',
      key: 'questionInDutch',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '35%',
    },
    // {
    //   title: enumTableTitleHabitQuestion.POSITION,
    //   dataIndex: 'position',
    //   key: 'position',
    //   showSorterTooltip: false,
    //   ellipsis: true,
    //   align: 'center',
    //   width: '10%',
    // },
    {
      title: enumTableTitleHabitQuestion.GOAL_ID,
      dataIndex: 'goalId',
      key: 'goalId',
      showSorterTooltip: false,
      ellipsis: true,
      align: 'center',
      width: '10%',
    },
    {
      title: enumTableTitleHabitQuestion.STATUS,
      dataIndex: 'status',
      key: 'status',
      showSorterTooltip: false,
      ellipsis: true,
      align: 'center',
      width: '10%',
    },
    {
      title: enumTableTitleHabitQuestion.ACTION,
      key: 'action',
      dataIndex: 'action',
      width: '10%',
      align: 'center',
      render: (record: IQuestionItem) => (
        <div className="flex space-x-4 items-center justify-center w-full">
          <Tooltip title={t('common:edit')}>
            <EditOutlined
              className="text-lg font-light mr-2.5 cursor-pointer !text-[#184f64] hover:opacity-80"
              onClick={() => {
                navigate(PATH_SUB_HABIT_QUESTION_DETAIL, {
                  state: { question: record },
                })
                setSelectedQuestion(record)
              }}
            />
          </Tooltip>

          <Tooltip
            title={
              record?.status
                ? "Can't delete active question"
                : t('common:delete')
            }
          >
            <DeleteOutlined
              className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 ${
                record?.status ? '!text-red-100' : '!text-red-600 '
              }`}
              onClick={() => {
                if (record?.status) return
                setOpenConfirmDeleteModal(true)
                setDeletedQuestion(record)
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ]

  useEffect(() => {
    getQuestions({ page: 1, goalId: state?.goalId })
  }, [state?.goalId])

  useEffect(() => {
    getQuestionsBySearchValue()
  }, [debouncedValue])

  return (
    <Card>
      <div className="flex items-center justify-between py-[1.25rem] gap-5 w-full">
        <div className="max-w-[500px] flex-1 flex gap-8">
          <Input
            prefix={<SearchOutlined className="pl-2" />}
            placeholder="Search question"
            value={searchText === null ? '' : searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            suffix={isSearching ? <Loading /> : undefined}
          />
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => {
              navigate(PATH_SUB_HABIT_QUESTION_DETAIL)
            }}
          >
            New question
          </Button>
        </div>
      </div>
      {questionLoading ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <SharedTable
          columns={columns as any}
          dataSource={
            question.questions &&
            question?.questions?.map((item, index) => {
              return {
                ...item,
                key: item?.id,
                no: index + 1,
                question: item?.question ?? `--`,
                questionInDutch: item?.questionInDutch ?? `--`,
                position: item?.position ?? `--`,
                goalId: item?.goalId ?? `--`,
                status: !!item?.status
                  ? EHabitGoalStatus.ACTIVE
                  : EHabitGoalStatus.INACTIVE,
                record: item,
                action: item,
              }
            })
          }
          sticky
          paginationProps={{
            total: question.total ?? 0,
            pageSize: 10,
            showSizeChanger: false,
            current: question.page ?? 1,
            onChange(page) {
              getQuestions({ page: page })
            },
          }}
        />
      )}

      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          title="Delete this question"
          content="Are you sure to delete this question?"
          open={openConfirmDeleteModal}
          isLoading={!!isModalLoading}
          onDelete={onDeleteQuestion}
          onClose={onCloseDeleteModal}
        />
      )}
    </Card>
  )
}

export default QuestionHabitPage
