import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { ShareSelectInput, SharedCheckbox } from '@components'
import { PATH_SUB_HABIT_QUESTION, thunkActionLoading } from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { Divider, Tooltip, message } from 'antd'
import { t } from 'i18next'
import { omit } from 'lodash'
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Input, SwitchButton, TextArea } from 'src/common'
import {
  IGetGoalParams,
  IGoalItem,
  IQuestionCreateFormData,
} from 'src/interfaces/habit-management'
import {
  createQuestionAction,
  getListGoalsAction,
  getListQuestionAction,
  updateQuestionAction,
} from 'src/redux/actions/habit-management'
import { z } from 'zod'

type Props = {}

const answersSchema = z.object({
  answer: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:answer_length_error') as string,
    }),
  answerInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:answer_length_error') as string,
    }),
  point: z
    .number()
    .min(1, {
      message: t('error:point_min_value_error') as string,
    })
    .max(10, {
      message: t('error:point_max_value_error') as string,
    }),
})

const questionSchema = z.object({
  question: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:question_length_error') as string,
    }),
  questionInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:questionInDutch_length_error') as string,
    }),
  goalId: z.number().min(1, { message: t('error:field_required') as string }),
  multipleChoice: z.boolean(),
  answers: z
    .array(answersSchema)
    .min(1, {
      message: t('error:answer_min_length_error') as string,
    })
    .max(5, {
      message: t('error:answer_max_length_error') as string,
    }),
  position: z.number().min(1, { message: t('error:field_required') as string }),
  status: z.boolean(),
})

const pointRange = new Array(10).fill(0).map((_, index) => {
  return {
    value: index + 1,
    label: (index + 1).toString(),
  }
})

const QuestionDetailPage = (props: Props) => {
  const { state } = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const indexRef = useRef<number>(0)

  const [isChange, setIsChange] = useState(false)

  // const [isMultipleChoice, setIsMultipleChoice] = useState(
  //   state ? !!state?.question?.multipleChoice : false
  // )

  const [sizeOfGoal, setSizeOfGoal] = useState<number | undefined>(undefined)

  const [selectedGoalId, setSelectedGoalId] = useState<number | undefined>(
    undefined
  )

  const goalLoading = useAppSelector(
    (state) => state.habit.loadings[thunkActionLoading.GET_LIST_GOALS_LOADING]
  )

  const { goal } = useAppSelector((state: RootState) => state.habit)

  const goalOptions = useMemo(() => {
    const data = goal?.goals?.map((item) => {
      return {
        value: item?.id,
        label: item?.goal,
      }
    })

    return data
  }, [goal?.goals])

  const positionRange = useMemo(() => {
    return new Array(sizeOfGoal ? sizeOfGoal + 1 : 1)
      .fill(0)
      .map((_, index) => {
        return {
          value: index + 1,
          label: (index + 1).toString(),
        }
      })
  }, [sizeOfGoal, selectedGoalId])

  // new Array(sizeOfGoal || 1).fill(0).map((_, index) => {
  //   return {
  //     value: index + 1,
  //     label: (index + 1).toString(),
  //   }
  // })

  // Init hook form - start
  const defaultValue: IQuestionCreateFormData = {
    question: state?.question?.question || '',
    questionInDutch: state?.question?.questionInDutch || '',
    goalId: state?.question?.goalId || 1,
    multipleChoice: state?.question?.multipleChoice || false,
    answers: state?.question?.answers || [],
    position: state?.question?.position || 1,
    status: state?.question?.status || false,
  }

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    reset,
    formState: { isDirty, errors },
  } = useForm<IQuestionCreateFormData>({
    defaultValues: defaultValue,
    resolver: zodResolver(questionSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control: control, // control props comes from useForm (optional: if you are using FormContext)
      name: 'answers', // unique name for your Field Array
    }
  )

  const getListQuestionByGoalId = async (goalId: number) => {
    try {
      const response = await dispatch(
        getListQuestionAction({
          page: 1,
          limit: 100000,
          goalId: goalId,
        })
      ).unwrap()
      if (response?.success) {
        setSizeOfGoal(response?.data?.total)
      }
    } catch (error: any) {
      console.log('error', error?.message)
    }
  }

  const onSave = async (data: IQuestionCreateFormData) => {
    if (state && state?.question && state?.question?.id) {
      try {
        const submitWithoutGoalId = omit(data, ['goalId'])
        const response = await dispatch(
          updateQuestionAction({
            data: submitWithoutGoalId,
            id: state?.question?.id,
          })
        ).unwrap()
        if (response?.success) {
          message.success(response.message)
          reset({
            question: response?.data?.updatedFirstQuestion?.question,
            questionInDutch:
              response?.data?.updatedFirstQuestion?.questionInDutch,
            goalId: response?.data?.updatedFirstQuestion?.goalId,
            multipleChoice:
              response?.data?.updatedFirstQuestion?.multipleChoice,
            answers: response?.data?.updatedFirstQuestion?.answers,
            position: response?.data?.updatedFirstQuestion?.position,
            status: response?.data?.updatedFirstQuestion?.status,
          })
        }
      } catch (error: any) {
        console.log('error', error)
      }
    } else {
      try {
        const response = await dispatch(createQuestionAction(data)).unwrap()
        if (response?.success) {
          message.success(response.message)
          navigate(PATH_SUB_HABIT_QUESTION)
        }
      } catch (error: any) {
        console.log('error', error)
      }
    }
  }

  const getGoals = async (params?: IGetGoalParams) => {
    dispatch(
      getListGoalsAction({
        page: 1,
        limit: 100000,
      })
    )
  }

  const isDisableStatus = useMemo(() => {
    if (state && state?.question && state?.question?.status) {
      return state?.question?.status ? true : false
    }
    return false
  }, [state?.question?.status])

  // useCallback(() => {
  //   if (state && state?.question && state?.question?.multipleChoice) {
  //     setIsMultipleChoice(true)
  //   } else {
  //     setIsMultipleChoice(false)
  //   }
  // }, [state])

  useEffect(() => {
    getListQuestionByGoalId(getValues('goalId'))
  }, [])

  useEffect(() => {
    if (fields?.length <= 0) {
      append({
        answer: '',
        answerInDutch: '',
        point: 1,
      })
    }
  }, [fields, fields?.length])

  useEffect(() => {
    getGoals()
  }, [])

  return (
    <div className="container bg-white">
      <div className="flex items-center justify-center flex-col">
        <h1 className="mb-5 mt-5 text-[32px] text-center ">
          {state && state?.question && state?.question?.id
            ? 'Question detail'
            : 'Create new question'}
        </h1>

        <form>
          <div className="question-wrapper">
            <Controller
              name={'question'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextArea
                    label="Question"
                    name="question"
                    required
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />
          </div>
          <div className="questionInDutch-wrapper mt-3">
            <Controller
              name={'questionInDutch'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextArea
                    label="Question in Netherlands"
                    name="questionInDutch"
                    required
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />
          </div>

          <Controller
            name={'goalId'}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <div className="w-full mt-3 grid grid-cols-7 gap-3">
                  <label className="label-goal col-span-2 flex items-center">
                    Goal
                  </label>
                  <div className="goal-input col-span-5 relative">
                    <ShareSelectInput
                      required
                      disabled={state?.question?.status}
                      data={
                        goalOptions
                          ? goalOptions
                          : [
                              {
                                label: 'Healthy',
                                value: 1,
                              },
                            ]
                      }
                      value={value}
                      style={{ width: '628px' }}
                      onChange={(value) => {
                        onChange(value)
                        setSelectedGoalId(+value)
                        getListQuestionByGoalId(+value)
                      }}
                    />
                  </div>
                </div>
              )
            }}
          />

          <Controller
            name="multipleChoice"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <div className="wrapper-multiple-choice w-full mt-3 grid grid-cols-7 gap-3 ">
                  <label className="label-multiple-choice col-span-2">
                    Multiple choice
                  </label>

                  <div className="wrap-checkbox-group col-span-5">
                    <div className="flex gap-10">
                      <SharedCheckbox
                        type="radio"
                        size="1rem"
                        text="Yes"
                        checked={value}
                        onChange={(event: any) => {
                          // setIsMultipleChoice((pre) => true)
                          onChange(event?.target?.checked)
                        }}
                      />
                      <SharedCheckbox
                        type="radio"
                        size="1rem"
                        text="No"
                        checked={!value}
                        onChange={(event: any) => {
                          // setIsMultipleChoice((pre) => false)
                          onChange(!event?.target?.checked)
                          // for (let i = fields.length; i >= 1; i--) {
                          //   remove(i)
                          // }
                        }}
                      />
                    </div>
                  </div>
                </div>
              )
            }}
          />

          <Divider orientation="left" orientationMargin={0}>
            Answers
          </Divider>

          {fields?.map((item, index) => {
            return (
              <Fragment key={item.id}>
                <div className="answer-item relative">
                  <div className="answer-item answer mb-5">
                    <Controller
                      name={`answers.${index}.answer`}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <Fragment>
                            <TextArea
                              label={`Answer ${index + 1}`}
                              isGrid
                              required
                              onChange={(e) => {
                                onChange(e?.target?.value)
                                setIsChange(true)
                              }}
                              value={value}
                              errors={error?.message}
                              containerClassName="mt-3"
                              // style={{
                              //   height: '18px',
                              // }}
                            />
                          </Fragment>
                        )
                      }}
                    />
                  </div>
                  <div className="answer-item answer mb-5">
                    <Controller
                      name={`answers.${index}.answerInDutch`}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <Fragment>
                            <TextArea
                              label={`Answer in Netherlands ${index + 1}`}
                              // name="content"
                              isGrid
                              required
                              // onChange={onChange}
                              onChange={(e) => {
                                onChange(e?.target?.value)
                                setIsChange(true)
                              }}
                              value={value}
                              errors={error?.message}
                              containerClassName="mt-3"
                              style={{ marginTop: 10 }}
                            />
                          </Fragment>
                        )
                      }}
                    />
                  </div>
                  {/* -----ANSWER CONTENT & POINT----- */}
                  <div className="answer-item point mb-5">
                    <Controller
                      name={`answers.${index}.point`}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <div className="w-full mt-3 grid grid-cols-7 gap-3 ">
                            <label className="label-multiple-choice col-span-2 flex items-center">
                              Point
                            </label>
                            <div className="select-point-input col-span-5 relative">
                              <ShareSelectInput
                                // label="Point"
                                // containerClassName="flex flex-row justify-between mt-3"
                                required
                                data={pointRange}
                                // defaultValue={optionsFilterStatus[0].label}
                                value={value}
                                style={{ width: '628px' }}
                                onChange={onChange}
                              />
                            </div>
                          </div>
                        )
                      }}
                    />
                  </div>

                  <Divider
                    dashed={index !== fields.length - 1}
                    className="m-0"
                  />

                  {/* Tail icon */}
                  <div
                    className="
                  answer-item tail-icon absolute top-1/2 right-[-80px] transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center w-10 h-10 rounded-full bg-[#e2e8f0] cursor-pointer"
                  >
                    {index !== fields.length - 1 ? (
                      <Tooltip
                        title={
                          fields.length <= 1
                            ? t('error:answer_min_length_error')
                            : 'Delete answer'
                        }
                      >
                        <DeleteOutlined
                          className={`w-full h-full flex justify-center items-center text-lg font-light hover:opacity-80 !text-red-600`}
                          style={{ display: 'flex' }}
                          onClick={() => {
                            fields.length > 1 && remove(index)
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={
                          fields.length === 5
                            ? t('error:answer_max_length_error')
                            : 'Add answer'
                        }
                      >
                        <PlusCircleOutlined
                          className={`w-full h-full flex justify-center items-center text-lg font-light hover:opacity-80 !text-[#184e62]`}
                          style={{ display: 'flex' }}
                          onClick={() => {
                            fields.length < 5 &&
                              append({
                                answer: '',
                                answerInDutch: '',
                                point: 1,
                              })
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                  {/* {isMultipleChoice ? (
                  ) : (
                    <></>
                  )} */}
                </div>
              </Fragment>
            )
          })}

          <Controller
            name="position"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <div className="w-full mt-3 grid grid-cols-7 gap-3">
                  <label className="label-position col-span-2 flex items-center">
                    Position (by goal)
                  </label>
                  <div className="select-point-input col-span-5 relative">
                    <ShareSelectInput
                      // containerClassName="flex flex-row justify-between mt-3"
                      required
                      data={positionRange}
                      value={value}
                      style={{ width: '628px' }}
                      onChange={onChange}
                    />
                  </div>
                </div>
              )
            }}
          />

          <div className="status mt-3 grid grid-cols-7 gap-3">
            <div className="status-label col-span-2 flex items-center">
              Status
            </div>
            <div className="col-span-5">
              <Controller
                name="status"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <SwitchButton
                      size="default"
                      onChange={onChange}
                      checked={value}
                      disabled={isDisableStatus}
                    />
                  )
                }}
              />
            </div>
          </div>

          <div className="mt-6">
            <div className="flex items-center mt-5 mb-5 justify-center gap-3">
              <Button
                htmlType="submit"
                type="default"
                size="middle"
                className="submit__btn login-btn"
                loading={false}
                onClick={() => navigate(PATH_SUB_HABIT_QUESTION)}
                // disabled={!isDirty}
              >
                {'Cancel'}
              </Button>

              <Button
                htmlType="submit"
                type="primary"
                size="middle"
                className="submit__btn login-btn"
                loading={false}
                onClick={handleSubmit(onSave)}
                disabled={!isDirty}
              >
                {'Save'}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default QuestionDetailPage
